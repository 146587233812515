import React from "react";
import "./App.css";

function App() {
  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-center bg-no-repeat"
      style={{ backgroundImage: "url('/background.jpg')" }}
    >
      <div className="absolute inset-0"></div>
      <div className="relative text-center text-white animate-fade-in p-8 rounded-md">
        <img
          src="./title-fotor.png"
          alt="Moosu"
          className="w-auto h-24 mx-auto"
        />
        <p className="subtitle text-2xl mt-4 font-comic font-bold">
          EM BREVE LANÇAMENTO
        </p>
      </div>
    </div>
  );
}

export default App;
